import { Fragment, FunctionalComponent, h } from "preact";
import { Route, Router, RouterOnChangeArgs } from "preact-router";
import { TranslateProvider } from "@denysvuika/preact-translate";

import LandingPage from "../routes/landing-page";

import AboutPage from "../routes/about";
import AboutFAQsPage from "../routes/about-faqs";
import AboutCompanyPage from "../routes/about-company";
import AboutPrivacyPage from "../routes/about-privacy";
import AboutTermsPage from "../routes/about-terms";

import ProfilePage from "../routes/profile-page";
import MediaPage from "../routes/media-page";
import NotFoundPage from "../routes/notfound";

import en from "../assets/i18n/en.json";
import { gtrack } from "../utils/analytics";

const translations = { en };

const App: FunctionalComponent = () => {
    let currentUrl: string;
    const handleRoute = (e: RouterOnChangeArgs) => {
        gtrack("config", "G-GV6Y7FDJVD", { page_path: e.url });

        currentUrl = e.url;
    };

    return (
        <Fragment>
            <div id="app">
                <div id="mod-c"></div>
                <TranslateProvider root="assets/i18n" lang="en" translations={translations}>
                    <Router onChange={handleRoute}>
                        <Route path="/" component={LandingPage} />

                        <Route path="/about" component={AboutPage} />
                        <Route path="/about/faqs" component={AboutFAQsPage} />
                        <Route path="/about/company" component={AboutCompanyPage} />
                        <Route path="/about/terms" component={AboutTermsPage} />
                        <Route path="/about/privacy" component={AboutPrivacyPage} />

                        <Route path="/at/:handle" component={ProfilePage} />
                        <Route path="/m/:mediaID" component={MediaPage} />

                        <NotFoundPage default />
                    </Router>
                </TranslateProvider>
                <div id="footer-notes"></div>
            </div>
        </Fragment>
    );
};

export default App;
