/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export function gtrack(...args: any): void {
    if (typeof window === "undefined") {
        return;
    }

    if (typeof window.googtag === "undefined") {
        return;
    }

    window.googtag(...args);
}
